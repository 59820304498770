import { default as authenticatedofN5d05haIMeta } from "/app/pages/authenticated.vue?macro=true";
import { default as calendarsHG7ZbpD8zMeta } from "/app/pages/calendar.vue?macro=true";
import { default as _91id_93aOtzRypsPRMeta } from "/app/pages/game-opportunities/[id].vue?macro=true";
import { default as indexfum1EhGoV5Meta } from "/app/pages/game-opportunities/index.vue?macro=true";
import { default as google_45authenticatedGYjStXfODpMeta } from "/app/pages/google-authenticated.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as _91id_93Kujv0wYEYHMeta } from "/app/pages/profile/[id].vue?macro=true";
import { default as editbUooxexoBEMeta } from "/app/pages/profile/edit.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as match_45historyADuao888EdMeta } from "/app/pages/profile/match-history.vue?macro=true";
import { default as upcoming_45gamesyGPXQX9lk5Meta } from "/app/pages/profile/upcoming-games.vue?macro=true";
import { default as riotersbdNkSCoQQnMeta } from "/app/pages/rioters.vue?macro=true";
export default [
  {
    name: "authenticated",
    path: "/authenticated",
    meta: authenticatedofN5d05haIMeta || {},
    component: () => import("/app/pages/authenticated.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/app/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "game-opportunities-id",
    path: "/game-opportunities/:id()",
    meta: _91id_93aOtzRypsPRMeta || {},
    component: () => import("/app/pages/game-opportunities/[id].vue").then(m => m.default || m)
  },
  {
    name: "game-opportunities",
    path: "/game-opportunities",
    meta: indexfum1EhGoV5Meta || {},
    component: () => import("/app/pages/game-opportunities/index.vue").then(m => m.default || m)
  },
  {
    name: "google-authenticated",
    path: "/google-authenticated",
    component: () => import("/app/pages/google-authenticated.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "profile-id",
    path: "/profile/:id()",
    meta: _91id_93Kujv0wYEYHMeta || {},
    component: () => import("/app/pages/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editbUooxexoBEMeta || {},
    component: () => import("/app/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexaeouN7Kzh7Meta || {},
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-match-history",
    path: "/profile/match-history",
    meta: match_45historyADuao888EdMeta || {},
    component: () => import("/app/pages/profile/match-history.vue").then(m => m.default || m)
  },
  {
    name: "profile-upcoming-games",
    path: "/profile/upcoming-games",
    meta: upcoming_45gamesyGPXQX9lk5Meta || {},
    component: () => import("/app/pages/profile/upcoming-games.vue").then(m => m.default || m)
  },
  {
    name: "rioters",
    path: "/rioters",
    meta: riotersbdNkSCoQQnMeta || {},
    component: () => import("/app/pages/rioters.vue").then(m => m.default || m)
  }
]